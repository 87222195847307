import { loadableReady } from '@loadable/component';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
import 'web/utils/sw-cleanup';

const rootElement = document.getElementById('root');
const renderer = (element: React.ReactElement) =>
  rootElement.children.length > 0 ? hydrateRoot(rootElement, element) : createRoot(rootElement).render(element);

loadableReady(() => {
  renderer(
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>,
  );
});
