import { httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFunctions from 'web/components/FirebaseContext/useFunctions';

const GeoIpContex = React.createContext<introwise.GeoIP | null>(null);

// Since our production deployment doesn't run the server-side rendering the IP geolocation data
// is not available in the initial data there. When it happens we locate the user by calling
// a cloud function.
// Set this flag to false to disable geoip location fetching when server-side value is not available.
const shouldFetchGeoIp = true;

const GeoIpProvider = ({ children }: { children: React.ReactNode }) => {
  const errorReporter = useErrorReporter();
  const [initialGeoIp] = useState(globalThis.__introwise_initial_data?.geoIp);
  const [geoIp, setGeoIp] = useState(initialGeoIp || null);
  const functions = useFunctions();
  useEffect(() => {
    const fetchGeoIp = async () => {
      try {
        const res = await httpsCallable<unknown, introwise.GeoIP>(functions, 'geoipLocate')({});
        setGeoIp(res.data);
      } catch (err) {
        errorReporter.report(new Error(`Failed to fetch a geoip location result: ${err}`));
      }
    };
    if (initialGeoIp === undefined && shouldFetchGeoIp) {
      fetchGeoIp();
    }
  }, [functions, initialGeoIp, errorReporter]);

  return <GeoIpContex.Provider value={geoIp}>{children}</GeoIpContex.Provider>;
};

export { GeoIpProvider };
export default GeoIpContex;
