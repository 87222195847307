import { getAuth } from 'firebase/auth';
import { useContext } from 'react';
import FirebaseContext from './FirebaseContext';

const useAuth = () => {
  const firebase = useContext(FirebaseContext);
  return getAuth(firebase);
};

export default useAuth;
