import React, { ReactElement, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import UserContext from './UserContext';

const payWallEnabled = true;

const PrivateRoute = ({
  waitForData,
  placeholder,
  children,
}: {
  waitForData?: boolean;
  placeholder?: ReactElement;
  children: React.ReactNode;
}) => {
  const { user, userData, loggingIn, loading } = useContext(UserContext);
  const location = useLocation();
  if (loggingIn) {
    return placeholder || <></>;
  }

  if (!user) {
    return (
      <Navigate
        to="/login"
        state={{
          from: location,
        }}
      />
    );
  }

  if (waitForData && loading) {
    return placeholder || <></>;
  }

  if ((payWallEnabled && loading) || (payWallEnabled && !userData)) {
    // Wait for userData availability. It could be undefined when there is a firestore error, or a network error.
    return placeholder || <></>;
  }

  if (payWallEnabled && !userData.pricingPlanId) {
    // Users without a plan are not allowed to view any private pages
    // We send them to signup page where they will be presented with a plan
    // selection
    return <Navigate to="/get-started" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
