import React, { useEffect } from 'react';
import './cookieBanner.css';

const CookieBanner = () => {
  useEffect(() => {
    const scriptInline = document.createElement('script');
    scriptInline.type = 'text/javascript';
    const config = {
      gdprAppliesGlobally: false,
      enableCcpa: true,
      countryDetection: true,
      consentOnContinuedBrowsing: false,
      ccpaAcknowledgeOnDisplay: true,
      lang: 'en',
      siteId: 1365907,
      cookiePolicyId: 54070999,
      banner: {
        rejectButtonDisplay: true,
        rejectButtonColor: '#539B9B',
        rejectButtonCaptionColor: 'white',
        position: 'float-bottom-left',
        textColor: 'black',
        backgroundColor: 'white',
        fontSize: 12,
        acceptButtonDisplay: true,
        acceptButtonColor: '#539B9B',
        acceptButtonCaptionColor: 'white',
        content: 'We use cookies to help make Introwise better. Learn more in our %{cookie_policy_link}.',
      },
    };
    scriptInline.innerHTML = `var _iub = _iub || []; _iub.csConfiguration = ${JSON.stringify(config)};`;
    document.body.appendChild(scriptInline);

    const scriptStub = document.createElement('script');
    scriptStub.src = 'https://cdn.iubenda.com/cs/ccpa/beta/stub.js';
    scriptStub.type = 'text/javascript';
    document.body.appendChild(scriptStub);

    const scriptCs = document.createElement('script');
    scriptCs.src = 'https://cdn.iubenda.com/cs/beta/iubenda_cs.js';
    scriptCs.type = 'text/javascript';
    scriptCs.async = true;
    document.body.appendChild(scriptCs);
  }, []);

  return <></>;
};

export default CookieBanner;
