import baseLoadable, { DefaultComponent } from '@loadable/component';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useRoutes } from 'react-router-dom';
import AdminRoute from 'web/components/AdminRoute';
import { BackButtonProvider } from 'web/components/BackButtonContext';
import CookieBanner from 'web/components/CookieBanner';
import ErrorBoundary from 'web/components/ErrorBoundary';
import { GeoIpProvider } from 'web/components/GeoIpContext';
import Meta from 'web/components/Meta';
import PageLoadingFallback from 'web/components/PageLoadingFallback';
import PageTracker from 'web/components/PageTracker';
import PrivateRoute from 'web/components/PrivateRoute';
import Rewrite from 'web/components/Rewrite';
import SubstituteUserWarning from 'web/components/SubstituteUserWarning';
import { TrackingProvider } from 'web/components/TrackingContext';
import { UserProvider } from 'web/components/UserContext';
import UserTracker from 'web/components/UserTracker';
import { RouteObjectWithLoader } from 'web/data/types';
import 'web/styles/GlobalStyle';

const loadable = <T,>(func: () => Promise<DefaultComponent<T>>, ssr = true) =>
  baseLoadable(func, { fallback: <PageLoadingFallback />, ssr });

const FrontPage = loadable(() => import('./FrontPage'));
const Login = loadable(() => import(/* webpackPrefetch: true */ './Login'));
const Logout = loadable(() => import('./Logout'));
const Signup = loadable(() => import(/* webpackPrefetch: true */ './Signup'));
const EmailAction = loadable(() => import('./EmailAction'), false);
const ResetPassword = loadable(() => import('./ResetPassword'));
const TermsOfService = loadable(() => import('./TermsOfService'));
const PrivacyPolicy = loadable(() => import('./PrivacyPolicy'));
const Pricing = loadable(() => import(/* webpackPrefetch: true */ './Pricing'));
const Faq = loadable(() => import(/* webpackPrefetch: true */ './Faq'));
const Welcome = loadable(/* webpackPrefetch: true */ () => import('./Welcome'));
const Onboarding = loadable(/* webpackPrefetch: true */ () => import('./Onboarding'));
const Admin = loadable(() => import('./Admin'));
const PaymentAction = loadable(() => import('./PaymentAction'), false);
const Dashboard = loadable(() => import(/* webpackPrefetch: true */ './Dashboard/Dashboard'));
const DashboardPartner = loadable(() => import('./DashboardPartner'));
const StripeOAuth = loadable(() => import('./StripeOAuth'), false);
const PayPalReturn = loadable(() => import('./PayPalReturn'));
const ZoomOAuth = loadable(() => import('./ZoomOAuth'));
const LeadMagnetHowToStart = loadable(/* webpackPrefetch: true */ () => import('./LeadMagnetHowToStart'));
const Session = loadable(() => import('./Session'));
const BookingPage = loadable(() => import(/* webpackPrefetch: true */ 'web/App/BookingPage'));
const BookingPageNew = loadable(() => import('web/App/BookingPageNew'));
const Contact = loadable(() => import('./Contact'));
const Careers = loadable(() => import('./Careers'));
const UseCases = loadable(() => import(/* webpackPrefetch: true */ 'web/App/UseCases'));
const Features = loadable(() => import(/* webpackPrefetch: true */ 'web/App/Features'));
const Blog = loadable(() => import('./Blog'));
const BlogIndex = loadable(() => import('./Blog/BlogIndex'));
const BlogPost = loadable(() => import('./Blog/BlogPost'));
const BlogAuthor = loadable(() => import('./Blog/BlogAuthor'));
const BlogCategory = loadable(() => import('./Blog/BlogCategory'));
const Directory = loadable(() => import('./Directory'));
const HelpCenter = loadable(() => import('./HelpCenter'));
const HelpCenterIndex = loadable(() => import('./HelpCenter/HelpIndex'));
const HelpCenterArticle = loadable(() => import('./HelpCenter/HelpArticle'));
const Community = loadable(() => import('./Community'));
const AppSumo = loadable(() => import('./AppSumo'));
const Feedback = loadable(() => import('./Feedback'));
const Resource = loadable(() => import('./Resource'));
const Partner = loadable(() => import('./Partners/Partner'));
const PartnerContainer = loadable(() => import('./Partners/PartnerContainer'));
const PartnerJoin = loadable(() => import('./Partners/PartnerJoin'));
const OAuthAction = loadable(() => import('./OAuthAction'), false);

export const routes: RouteObjectWithLoader[] = [
  {
    index: true,
    element: <FrontPage />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/get-started',
    element: <Signup />,
  },
  {
    path: '/appsumo/*',
    element: <AppSumo />,
  },
  {
    path: '/email-action',
    element: <EmailAction />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/pricing',
    element: <Pricing />,
  },
  {
    path: '/faq',
    element: <Faq />,
    loader: (match) => import('web/data/loaders').then(({ faqLoader }) => faqLoader(match)),
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/careers/*',
    element: <Careers />,
  },
  {
    path: '/blog/*',
    element: <Blog />,
    children: [
      {
        index: true,
        element: <BlogIndex />,
        loader: (match) => import('web/data/loaders').then(({ blogLoader }) => blogLoader(match)),
      },
      {
        path: ':slug',
        element: <BlogPost />,
        loader: (match) => import('web/data/loaders').then(({ blogPostLoader }) => blogPostLoader(match)),
      },
      {
        path: 'author/:slug',
        element: <BlogAuthor />,
        loader: (match) => import('web/data/loaders').then(({ blogAuthorLoader }) => blogAuthorLoader(match)),
      },
      {
        path: 'category/:slug',
        element: <BlogCategory />,
        loader: (match) => import('web/data/loaders').then(({ blogCategoryLoader }) => blogCategoryLoader(match)),
      },
    ],
  },
  {
    path: '/directory/*',
    element: <Directory />,
    loader: (match) => import('web/data/loaders').then(({ directoryListingLoader }) => directoryListingLoader(match)),
  },
  {
    path: '/community/*',
    element: (
      <AdminRoute>
        <Community />
      </AdminRoute>
    ),
  },
  {
    path: '/help/*',
    element: <HelpCenter />,
    children: [
      {
        index: true,
        element: <HelpCenterIndex />,
        loader: (match) => import('web/data/loaders').then(({ helpCenterLoader }) => helpCenterLoader(match)),
      },
      {
        path: ':slug',
        element: <HelpCenterArticle />,
        loader: (match) => import('web/data/loaders').then(({ helpArticleLoader }) => helpArticleLoader(match)),
      },
    ],
  },
  {
    path: '/feedback/*',
    element: <Feedback />,
  },
  {
    path: '/welcome/*',
    element: <Welcome />,
  },
  {
    path: '/onboarding/*',
    element: <Onboarding />,
  },
  {
    path: '/admin/*',
    element: (
      <AdminRoute>
        <Admin />
      </AdminRoute>
    ),
  },
  {
    path: '/payment-action',
    element: <PaymentAction />,
  },
  {
    path: '/oauth/user-action',
    element: (
      <PrivateRoute waitForData placeholder={<PageLoadingFallback />}>
        <OAuthAction />
      </PrivateRoute>
    ),
  },
  {
    path: '/dashboard/payments/stripe-oauth',
    element: (
      <PrivateRoute waitForData placeholder={<PageLoadingFallback />}>
        <StripeOAuth />
      </PrivateRoute>
    ),
  },
  {
    path: '/dashboard/payments/paypal-return',
    element: (
      <PrivateRoute waitForData placeholder={<PageLoadingFallback />}>
        <PayPalReturn continueTo="/dashboard/payments/accounts" />
      </PrivateRoute>
    ),
  },
  {
    path: '/settings/scheduling/zoom-oauth',
    element: (
      <PrivateRoute waitForData placeholder={<PageLoadingFallback />}>
        <ZoomOAuth />
      </PrivateRoute>
    ),
  },
  {
    path: '/dashboard/*',
    element: (
      <PrivateRoute waitForData placeholder={<PageLoadingFallback />}>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: '/dashboard-partner/*',
    element: (
      <PrivateRoute waitForData placeholder={<PageLoadingFallback />}>
        <DashboardPartner />
      </PrivateRoute>
    ),
  },
  {
    path: '/home/*',
    element: <Rewrite to={(from) => `/dashboard${from}`} />,
  },
  {
    path: '/settings/*',
    element: <Rewrite to={(from) => `/dashboard${from.replace('/settings', '')}`} />,
  },
  {
    path: '/academy/how-to-start',
    element: <LeadMagnetHowToStart />,
  },
  {
    path: '/s/:sessionId',
    element: <Session />,
  },
  {
    path: '/r/:resourceId',
    element: <Resource />,
  },
  {
    path: '/uses/:slug',
    element: <UseCases />,
    loader: (match) => import('web/data/loaders').then(({ useCasePageLoader }) => useCasePageLoader(match)),
  },
  {
    path: '/features',
    element: <Rewrite to={() => '/'} />,
  },
  {
    path: '/features/:slug',
    element: <Features />,
    loader: (match) => import('web/data/loaders').then(({ featuresPageLoader }) => featuresPageLoader(match)),
  },
  {
    path: '/partners/*',
    children: [
      {
        index: true,
        element: <Navigate to="/" />,
      },
      {
        path: ':slug',
        element: <PartnerContainer />,
        loader: (match) => import('web/data/loaders').then(({ partnerPageLoader }) => partnerPageLoader(match)),
        children: [
          {
            index: true,
            element: <Partner />,
          },
          {
            path: 'join',
            element: <PartnerJoin />,
          },
        ],
      },
    ],
  },
  {
    path: '/:slug/*',
    element: <BookingPage />,
    loader: (match) => import('web/data/loaders').then(({ bookingPageLoader }) => bookingPageLoader(match)),
  },
  // TODO: add booking page session route and loader here
  {
    path: '/p/:slug/*',
    element: <BookingPageNew />,
  },
];

const useStrictMode = false;
const Strict = useStrictMode ? React.StrictMode : React.Fragment;

const App = () => {
  const element = useRoutes(routes);
  return (
    <Strict>
      <Helmet defaultTitle="Introwise" titleTemplate="%s – Introwise">
        <meta property="og:site_name" content="Introwise" />
      </Helmet>
      <Meta
        title="Business Platform For Experts"
        description="Introwise makes it easy for everyday experts to offer coaching and advice online."
        image="https://introwise.com/images/introwise-meta-image-20200917.png"
      />
      <CookieBanner />
      {/* <RemoteConfigProvider> */}
      <GeoIpProvider>
        <UserProvider>
          <TrackingProvider>
            <BackButtonProvider>
              <UserTracker />
              <PageTracker />
              <ErrorBoundary>
                <SubstituteUserWarning />
                {element}
              </ErrorBoundary>
            </BackButtonProvider>
          </TrackingProvider>
        </UserProvider>
      </GeoIpProvider>
      {/* </RemoteConfigProvider> */}
    </Strict>
  );
};

export default App;
