import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import UserContext from './UserContext';

const PrivateAdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { claims, claimsLoading } = useContext(UserContext);

  if (claimsLoading) {
    return <></>;
  }

  if (!claims.admin) {
    return <Navigate to="/home" />;
  }

  return <>{children}</>;
};

const AdminRoute = ({ children }: { children: React.ReactNode }) => (
  <PrivateRoute>
    <PrivateAdminRoute>{children}</PrivateAdminRoute>
  </PrivateRoute>
);

export default AdminRoute;
