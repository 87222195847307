import { useContext, useEffect, useState } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import FirebaseContext from './FirebaseContext';

const lazyFirestore: { [appName: string]: typeof import('./getFirestore') } = {};

const useFirestoreLazy = () => {
  const firebase = useContext(FirebaseContext);
  const errorReporter = useErrorReporter();
  const [firestore, setFirestore] = useState(lazyFirestore[firebase.name]);
  useEffect(() => {
    if (!firestore) {
      import('./getFirestore')
        .then((exports) => {
          lazyFirestore[firebase.name] = exports;
          setFirestore(exports);
        })
        .catch((error) => errorReporter.report(error));
    }
  }, [firebase.name, firestore, errorReporter]);
  return firestore;
};

export default useFirestoreLazy;
