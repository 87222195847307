import React, { useContext, useEffect, useRef } from 'react';
import TrackingContext from './TrackingContext';
import UserContext from './UserContext';

const UserTracker = () => {
  const tracking = useContext(TrackingContext);
  const { user, userData, loading, loggingIn } = useContext(UserContext);
  const prevUser = useRef<typeof user>(null);
  const prevUserData = useRef<typeof userData>(null);
  useEffect(() => {
    if (!loggingIn && user !== prevUser.current) {
      tracking.trackUser(user?.uid, user ? { email: user?.email } : undefined);
      prevUser.current = user;
    }
  }, [user, loggingIn, tracking]);
  useEffect(() => {
    if (!loading && userData && userData.pricingPlanId !== prevUserData.current?.pricingPlanId) {
      tracking.trackUser(userData.id, { pricingPlanId: userData.pricingPlanId });
      prevUserData.current = userData;
    }
  }, [userData, loading, tracking]);

  return <></>;
};

export default UserTracker;
