import { signInWithCustomToken } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import { Button } from './elements';
import useAuth from './FirebaseContext/useAuth';
import useFunctions from './FirebaseContext/useFunctions';
import sc from './styled';
import UserContext from './UserContext';

const WarningStyled = sc.div(
  themeClasses({ display: 'flex', justifyContent: 'space-between', paddingY: 1, paddingX: 4 }),
  { color: '#fff', backgroundColor: themeConstants.palette.peach[550] },
);

const Warning = () => {
  const functions = useFunctions();
  const auth = useAuth();
  const { user, claims } = useContext(UserContext);
  const [switching, setSwitching] = useState(false);
  const navigate = useNavigate();
  const errorReporter = useErrorReporter();

  const switchBack = async () => {
    setSwitching(true);
    try {
      const res = await httpsCallable<unknown, { token: string }>(
        functions,
        'authLoginSubstituteUser',
      )({ uid: claims?.suUid });
      const { token } = res.data;
      await signInWithCustomToken(auth, token);
      navigate('/admin/users');
    } catch (err) {
      errorReporter.report('Failed to switch back:', err);
    }
    setSwitching(false);
  };

  return (
    <WarningStyled>
      <div>
        <span>
          Logged in as <b>{user.displayName}</b>
        </span>
        {claims.suExp && (
          <span style={{ marginLeft: 16 }}>
            Your session expires at {new Date(Number(claims.suExp)).toLocaleTimeString('en')}
          </span>
        )}
      </div>
      <div>
        <Button secondary xs onClick={switchBack} disabled={switching} style={{ background: '#fff' }}>
          Switch to your account
        </Button>
      </div>
    </WarningStyled>
  );
};

const SubstituteUserWarning = () => {
  const { claims } = useContext(UserContext);

  if (claims?.suUid) {
    return <Warning />;
  }

  return <></>;
};

export default SubstituteUserWarning;
