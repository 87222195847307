import React, { AnchorHTMLAttributes } from 'react';
import joinClassNames from 'web/utils/joinClassNames';
import button, { ButtonVariants, OldButtonVariants } from './button.css';
import linkReset from './linkReset.css';

const AnchorButton = ({
  variant,
  size,
  primary,
  secondary,
  xs,
  sm,
  md,
  lg,
  className,
  children,
  ...props
}: React.DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> &
  ButtonVariants &
  OldButtonVariants) => (
  <a
    className={joinClassNames(
      className,
      linkReset,
      button({
        variant: variant || (primary ? 'primary' : secondary ? 'secondary' : undefined),
        size: size || (xs ? 'xs' : sm ? 'sm' : md ? 'md' : lg ? 'lg' : undefined),
      }),
    )}
    {...props}
  >
    {children}
  </a>
);

export default AnchorButton;
