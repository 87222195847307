import React from 'react';
import { Navigate, useHref, useLocation } from 'react-router';

const Rewrite = ({ to }: { to: (from: string) => string }) => {
  const location = useLocation();
  const href = useHref(location);
  const newHref = to(href);
  return <Navigate to={newHref} replace />;
};

export default Rewrite;
